






























































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { InteractConfig } from '@/types';
import { EventService } from '@/services/interact/EventService';
import { not } from 'rxjs/internal-compatibility';
import eventBus from '@/eventBus';
import { VuetifyThemeItem } from 'vuetify/types/services/theme';

const ToolbarOptionNS = namespace('ToolbarOption');
const InteractEventsNS = namespace('InteractEvents');
const InteractChatConfigNS = namespace('InteractChatConfig');
const messages = namespace('Messages');

interface CallPromise {
  promise: string;
  accepted: boolean;
}

@Component({
  components: {}
})
export default class AutoNotes extends Vue {
  promises: Array<CallPromise> = [];
  loading = false;

  currentChatSessionId = '';

  systemNotes = '';
  notes: Array<Note> = new Array<Note>();

  dispositionValues = ['Termination Request', 'Fee Reversal', 'New Offer Accepted'];
  selectedDisposition: Array<string> = [];

  userNoteInput = '';

  buttonLoading = false;
  buttonDisabled = false;
  buttonText = 'Log Call';

  constructor() {
    super();
  }

  @ToolbarOptionNS.Action
  setBadge!: (event: { name: string; badge: number | string }) => void;

  @InteractEventsNS.Action
  addEventService!: (config: {
    tenantId: string;
    apiKey: string;
    environment: 'Dev' | 'Test' | 'Prod';
  }) => Promise<string>;

  @InteractChatConfigNS.State
  config!: InteractConfig;

  @InteractEventsNS.State
  eventService!: EventService;

  @messages.Action
  sendMessageWithVariables!: (data: { text: string; variables: any }) => Promise<void>;

  private removePromise(promise: CallPromise) {
    this.promises = this.promises.filter((p: CallPromise) => {
      return p.promise !== promise.promise;
    });
  }

  mounted() {
    eventBus.$on('Disposition', (data: Array<any>) => {
      data.forEach((dispo, i) => {
        const foundValues = this.dispositionValues.filter((value) => value === dispo.value);
        if (foundValues.length === 0 && dispo.value) {
          this.dispositionValues.push(dispo.value);
        }
        this.selectedDisposition[i] = dispo.value;
      });
    });

    eventBus.$on('Summary', (data: Array<string>) => {
      let notes = '';
      data.forEach((note) => {
        if (!note.includes('entity_')) {
          notes += note + '\n';
        }
      });
      this.systemNotes = notes;
    });
  }

  get dispositionValueEntries() {
    return Object.entries(this.dispositionValues);
  }

  addUserNote() {
    this.notes.push({ noteType: 'user', text: this.userNoteInput, systemName: 'AgentAssist' });
    this.userNoteInput = '';
  }

  logCall() {
    this.buttonLoading = true;
    const noteString = this.systemNotes;

    let disposition = '';
    this.selectedDisposition.forEach((disp, index) => {
      disposition += `${index ? ', ' : ''}${disp}`;
    });

    this.sendMessageWithVariables({
      text: 'crm_log_call',
      variables: {
        disposition: disposition,
        notes: noteString
      }
    }).then(() => {
      this.buttonText = 'Call Logged';
      this.buttonLoading = false;
      this.buttonDisabled = true;
    });
  }

  connectEventService(newConfig: InteractConfig) {
    if (!this.eventService) {
      console.warn('No Event Service initialized yet');
      setTimeout(() => {
        this.connectEventService(newConfig);
      }, 500);
    } else {
      console.log('Listen for auto notes at session id', {
        id: '/' + newConfig.chatSessionId + '/notes'
      });
      this.eventService.subscribe('/' + newConfig.chatSessionId + '/notes', (message) => {
        const eventData = JSON.parse(message.body);
        const note = eventData.data as Note;

        this.systemNotes += `${note.text}\n`;

        this.notes.push(note);
        this.setBadge({
          name: 'Notes',
          badge: '!'
        });

        if (note.noteType === 'disposition' || note.noteType === 'system disposition') {
          this.handleDispositionEvent(note);
        } else if (note.noteType === 'promise') {
          this.promises.push({ promise: note.text, accepted: false });
        }
      });
    }
  }

  handleDispositionEvent(note: Note) {
    const foundValues = this.dispositionValues.filter((value) => value === note.disposition);
    if (foundValues.length === 0 && note.disposition) {
      this.dispositionValues.push(note.disposition);
    }

    if (note.disposition && !this.selectedDisposition.includes(note.disposition)) {
      this.selectedDisposition.push(note.disposition);
    }

    if (this.selectedDisposition.length > 5) {
      this.selectedDisposition.shift();
    }
  }

  needsDark(color: VuetifyThemeItem | string | number): boolean {
    if (typeof color === 'string') {
      color = color.slice(1, color.length);

      const red = parseInt(color.substring(0, 1));
      const green = parseInt(color.substring(2, 3));
      const blue = parseInt(color.substring(4, 5));

      //Color theory I found on the internet, don't ask me to explain it
      return red * 0.299 + green * 0.587 + blue * 0.114 < 150;
    }

    return false;
  }

  @Watch('config')
  async onConfigChanged(newConfig: InteractConfig) {
    this.connectEventService(newConfig);
  }
}

interface Note {
  text: string;
  systemName: string;
  noteType: 'system' | 'user' | 'disposition' | 'system disposition' | 'promise';
  disposition?: string;
}
