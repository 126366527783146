










import { Component, Vue, Watch } from 'vue-property-decorator';
import Toolbar from '@/components/Toolbar.vue';
import ContentDrawer from '@/components/ContentDrawer.vue';
import { namespace } from 'vuex-class';
import ToolbarOption, { ToolbarOptionEntry } from '@/store/modules/ToolbarOption';
import Tester from '@/components/Tester.vue';
import uAssistEventService from '@/services/u-assist-event.service';
import { InteractConfig } from '@/types';
import { EventService } from '@/services/interact/EventService';
import { NextBestActionEntry } from '@/store/modules/NextBestAction';
import DemoConfig from '@/store/modules/DemoConfig';
import { DemoConfigItem } from '@/services/demo-config.service';
import { TriggerIntentFlow } from '@/services/interact/types/intent/intent-request';
import store from '@/store';

const toolbarOption = namespace('ToolbarOption');
const transcription = namespace('Transcription');
const nextBestAction = namespace('NextBestAction');
const interactConfig = namespace('InteractChatConfig');
const InteractEventsNS = namespace('InteractEvents');
const messages = namespace('Messages');
const DemoConfigNS = namespace('DemoConfig');
import eventBus from '@/eventBus';
import { SpeechEvent } from '@/services/types/speech';
import { TranscriptionMessageEntry } from '@/store/modules/Transcription';
import axios from 'axios';
import { NotificationObj } from '@/store/modules/Messages';

@Component({
  components: {
    Toolbar,
    ContentDrawer,
    Tester
  }
})
export default class UAssist extends Vue {
  private flexDirection = this.$route.params.side === 'right' ? 'row-reverse' : 'row';

  @transcription.Action
  public addMessage!: (message: TranscriptionMessageEntry) => void;

  @messages.Action
  public handleAlertEvents!: (event: SpeechEvent) => void;

  @DemoConfigNS.State
  demoConfigItem!: DemoConfigItem;

  @toolbarOption.Action
  private pushToolbarOption!: (toolbarOption: ToolbarOptionEntry | ToolbarOptionEntry[]) => void;

  @transcription.Action
  private subscribeToTwilioTranscript!: () => void;

  // used to be nextBestAction
  @messages.Action
  private subscribeToTwilioIntent!: () => void;

  @interactConfig.Action
  public updateConfig!: (config: InteractConfig) => void;

  @interactConfig.State
  config!: InteractConfig;

  @interactConfig.State
  botId!: string;

  @InteractEventsNS.Action
  addEventService!: (config: {
    tenantId: string;
    apiKey: string;
    environment: 'Dev' | 'Test' | 'Prod';
  }) => Promise<string>;

  @InteractEventsNS.State
  eventService!: EventService;

  @messages.Action
  private initBotId!: () => void;

  @messages.Action
  private beginChat!: () => void;

  @messages.Action
  private beginUAssistSession!: (callId: string) => void;

  @messages.Action
  public addBotResponseOnly!: (botResponseText: string) => void;

  @messages.Action
  public triggerHiddenInput!: (botResponseText: string) => void;

  @messages.Action
  public triggerVisibleInput!: (botResponseText: string) => void;

  @messages.Action
  public addNextBestActionEntry!: (entry: NextBestActionEntry) => void;

  @messages.Action
  public triggerHiddenIntentFlow!: (triggerRequest: TriggerIntentFlow) => void;

  @DemoConfigNS.Action
  loadConfigById!: (configId: string) => void;

  @toolbarOption.State
  telephonyActive!: boolean;

  @messages.Action
  private addNotification!: (notification: NotificationObj) => void;

  get getTelephonyActive(): boolean {
    return this.telephonyActive;
  }

  @Watch('botId')
  async onBotIdChanged(botId: string) {
    await this.registerAssistantEvents(botId);
    await this.registerUAssistEvents(botId);
  }

  private async registerAssistantEvents(botId: string) {
    if (!this.eventService) {
      console.log('no event service yet');
      setTimeout(async () => {
        await this.registerAssistantEvents(botId);
      }, 500);
    } else {
      console.log('subscribing to ' + botId + '/assistantEvents');
      this.eventService.subscribe('/' + botId + '/assistantEvents', (message) => {
        const parsedMessage = JSON.parse(message.body);
        console.log('Received event from assistantEvents', message.body);
        console.log('Received event from assistantEvents', parsedMessage);

        const eventData = parsedMessage.data as {
          eventType: 'trigger_hidden' | 'show_response_only' | 'trigger_visible' | 'next_best_action';
          text: string;
          name?: string;
          variableName?: string;
          variableValue?: string;
        };

        let stringToSend = eventData.text;

        switch (eventData.eventType) {
          case 'show_response_only':
            this.addBotResponseOnly(eventData.text);
            break;
          case 'trigger_hidden':
            if (eventData.variableName) {
              stringToSend += '##' + eventData.variableName + '###' + eventData.variableValue;
            }

            this.triggerHiddenInput(stringToSend);

            break;
          case 'trigger_visible':
            this.triggerVisibleInput(eventData.text);
            break;
          case 'next_best_action':
            this.addNextBestActionEntry({
              name: eventData.name,
              questionText: eventData.text
            });
            break;
          default:
            console.warn('Unknown event type received!', {
              parsedMessage: parsedMessage,
              eventData: eventData
            });
        }
      });
    }
  }

  async mounted() {
    // TODO - Maybe move this to the store, but then the two next toolbar options
    // would be added anyways, even if the functionality is not yet available.

    if (!this.demoConfigItem && this.$route.params.configId) {
      this.loadConfigById(this.$route.params.configId);
    }

    await this.addEventService(this.config);

    this.updateConfig((this.$route.query as unknown) as InteractConfig);

    const features = [
      {
        badge: 0,
        icon: 'fal fa-list',
        name: 'Knowledge',
        state: false,
        order: 4
      },
      {
        badge: 0,
        icon: 'fal fa-file-alt',
        name: 'Summary',
        state: false,
        order: 5
      },
      {
        badge: 0,
        icon: 'fal fa-id-card',
        name: 'Customer Info',
        state: false,
        order: 1
      },
      {
        badge: 0,
        icon: 'fal fa-rocket',
        name: 'Next Best Action',
        state: true,
        order: 0
      },
      {
        badge: 0,
        icon: 'fal fa-microphone',
        name: 'Transcript',
        state: false,
        order: 2
      },
      {
        badge: 0,
        icon: 'fal fa-bell',
        name: 'Notifications and Promises',
        state: false,
        order: 3
      }
    ];

    if (this.demoConfigItem) {
      const filteredFeatures = new Array<ToolbarOptionEntry>();

      features.map((value) => {
        if (this.demoConfigItem.features.find((value1) => value1.name === value.name)) {
          filteredFeatures.push(value);
        }
      });
      // Pushing all Features for UI tests
      this.pushToolbarOption(filteredFeatures);

      const config: InteractConfig = {
        domainName:
          this.demoConfigItem.configItems.find((item) => {
            return item.name === 'interact_domain';
          })?.value || '',
        tenantId:
          this.demoConfigItem.configItems.find((item) => {
            return item.name === 'interact_tenant';
          })?.value || '',
        apiKey:
          this.demoConfigItem.configItems.find((item) => {
            return item.name === 'interact_app_key';
          })?.value || '',
        language:
          this.demoConfigItem.configItems.find((item) => {
            return item.name === 'language';
          })?.value || 'English',
        environment: 'Dev',
        mode: 'StandAlone'
      };
      if (config.domainName || config.tenantId || config.apiKey) {
        this.updateConfig(config);
      }
    } else {
      this.pushToolbarOption(features);
    }

    this.demoConfigItem.configItems.forEach((item) => {
      if (item.description.includes('theme')) {
        this.$vuetify.theme.themes.light[item.name] = item.value;
      }
    });

    this.initBotId();
  }

  private async registerUAssistEvents(botId: string) {
    const subject = await uAssistEventService.startListening(this.eventService, this.demoConfigItem, botId);
    subject.subscribe(async (uAssistEvent: any) => {
      try {
        if (uAssistEvent.alerts) {
          const eventPayload = uAssistEvent.alerts[0];

          if (eventPayload.type === 'Coaching Alert') {
            if (
              eventPayload.name
                .split('_')[0]
                .toLowerCase()
                .includes('knowledge')
            ) {
              this.handleAlertEvents({
                message: eventPayload.name.split('_')[2],
                name: 'popup',
                sendAt: 0
              });
            } else {
              this.addNotification({
                message: eventPayload.message,
                color: eventPayload.name.split('_')[1] || '',
                icon: eventPayload.name.split('_')[2] || ''
              });
            }
          } else if (eventPayload.type === 'Critical Alert') {
            console.log('Critical Alert:', eventPayload);
            const triggerIntentFlowEvent = this.extractEventConfig(eventPayload);

            const nba: NextBestActionEntry = { name: eventPayload.name, flowEvent: triggerIntentFlowEvent };

            await this.addNextBestActionEntry(nba);
          } else if (eventPayload.type === 'Information Alert') {
            //TODO - Define this
            console.log('Info Alert:', eventPayload);
            await uAssistEventService.generateSummary(botId);
          }
        } else if (uAssistEvent.transcript) {
          uAssistEvent.transcript.data.forEach((line: any) => {
            const message = {
              from: line.channel,
              message: line.keyPhrase,
              sendAt: 0
            };

            this.addMessage(message);
          });
        } else if (uAssistEvent.disposition) {
          eventBus.$emit('Disposition', uAssistEvent.disposition.data);
        } else if (uAssistEvent.summary && uAssistEvent.summary.data.format === 'bulletPoint') {
          eventBus.$emit('Summary', uAssistEvent.summary.data.bullet);

          uAssistEvent.summary.data.bullet.forEach((bullet: string) => {
            if (bullet.includes('entity_')) {
              const splitString = bullet.split('_');
              const key = splitString[1];
              const value = splitString[2].trim();

              if (value) {
                const entityObject: { [key: string]: string } = {};
                entityObject[key] = value;
                console.log('Emitting EntityAlert', entityObject);
                eventBus.$emit('EntityAlert', entityObject);
              }
            }
          });
        }

        if (uAssistEvent.entity) {
          // do some stuff

          eventBus.$emit('EntityAlert', uAssistEvent.entity);
        }

        if (uAssistEvent.metaData) {
          if (uAssistEvent.metaData.event.includes('start')) {
            this.beginUAssistSession(uAssistEvent.metaData.callId);
          }
        }
      } catch (e) {
        console.error('Error handling U-Assist event' + JSON.stringify(uAssistEvent), e);
      }
    });
  }

  // FlowId___b0af890e1275-c7a75343b52de599-88c7___Variable___key1_value1__key2_value2
  private extractEventConfig(payload: any): TriggerIntentFlow {
    const res: TriggerIntentFlow = {
      flowId: '',
      variables: {},
      autoTrigger: false
    };

    const splitted = payload.message.split('___');
    if (splitted[0].includes('Auto')) {
      res.autoTrigger = true;
    }

    res.flowId = splitted[1];

    if (splitted.length > 3) {
      const vars = splitted[3];
      const varsSplitted = vars.split('__');
      varsSplitted.map((value: string) => {
        const key = value.split('_')[0];
        const val = value.split('_')[1];
        res.variables[key] = val;
      });
    }

    return res;
  }
}
