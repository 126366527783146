var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-1 mb-1 agent-panel",staticStyle:{"display":"flex","flex-direction":"column"},attrs:{"color":"warmgray","flat":"","height":"100vh"}},[_c('transition',{attrs:{"name":"slide"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.activeToolbarOption &&
          _vm.activeToolbarOption.state &&
          _vm.activeToolbarOption.name === 'Notifications and Promises' &&
          !_vm.wait
      ),expression:"\n        activeToolbarOption &&\n          activeToolbarOption.state &&\n          activeToolbarOption.name === 'Notifications and Promises' &&\n          !wait\n      "}],staticStyle:{"flex":"1","overflow-y":"auto"}},[_c('notification-promises')],1)]),_c('transition',{attrs:{"name":"slide"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.activeToolbarOption && _vm.activeToolbarOption.state && _vm.activeToolbarOption.name === 'Next Best Action' && !_vm.wait
      ),expression:"\n        activeToolbarOption && activeToolbarOption.state && activeToolbarOption.name === 'Next Best Action' && !wait\n      "}],staticStyle:{"flex":"1","overflow-y":"auto"}},[_c('ChatView')],1)]),_c('transition',{attrs:{"name":"slide"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeToolbarOption && _vm.activeToolbarOption.state && _vm.activeToolbarOption.name === 'Transcript' && !_vm.wait),expression:"activeToolbarOption && activeToolbarOption.state && activeToolbarOption.name === 'Transcript' && !wait"}],staticStyle:{"flex":"1","overflow-y":"auto"}},[_c('Transcription')],1)]),_c('transition',{attrs:{"name":"slide"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeToolbarOption && _vm.activeToolbarOption.state && _vm.activeToolbarOption.name === 'Guidance' && !_vm.wait),expression:"activeToolbarOption && activeToolbarOption.state && activeToolbarOption.name === 'Guidance' && !wait"}],staticStyle:{"flex":"1","overflow-y":"auto"}},[_c('Guidance')],1)]),_c('transition',{attrs:{"name":"slide"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeToolbarOption && _vm.activeToolbarOption.state && _vm.activeToolbarOption.name === 'Knowledge' && !_vm.wait),expression:"activeToolbarOption && activeToolbarOption.state && activeToolbarOption.name === 'Knowledge' && !wait"}],staticStyle:{"flex":"1","overflow-y":"auto"}},[_vm._v(" > "),_c('Knowledge')],1)]),_c('transition',{attrs:{"name":"slide"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeToolbarOption && _vm.activeToolbarOption.state && _vm.activeToolbarOption.name === 'Summary' && !_vm.wait),expression:"activeToolbarOption && activeToolbarOption.state && activeToolbarOption.name === 'Summary' && !wait"}],staticStyle:{"flex":"1","overflow-y":"auto"}},[_c('AutoNotes')],1)]),_c('transition',{attrs:{"name":"slide"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.activeToolbarOption && _vm.activeToolbarOption.state && _vm.activeToolbarOption.name === 'Customer Info' && !_vm.wait
      ),expression:"\n        activeToolbarOption && activeToolbarOption.state && activeToolbarOption.name === 'Customer Info' && !wait\n      "}],staticStyle:{"flex":"1","overflow-y":"auto"}},[_c('CustomerInfo')],1)]),_c('transition',{attrs:{"name":"slide"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeToolbarOption && _vm.activeToolbarOption.state && _vm.activeToolbarOption.name === 'Promises' && !_vm.wait),expression:"activeToolbarOption && activeToolbarOption.state && activeToolbarOption.name === 'Promises' && !wait"}],staticStyle:{"flex":"1","overflow-y":"auto"}},[_c('Promises')],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }